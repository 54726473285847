<template>
	<div class="app-manager" :class="classList">
		<navbar />
		<sidebar />
		<main class="app-view-container">
			<router-view class="app-view" />
		</main>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Navbar from '@/components/Navbar.vue'
import Sidebar from '@/components/Sidebar.vue'
import Bugsnag from '@bugsnag/js'

export default {
	/**
	 * The component's registered child components.
	 *
	 * @type {Object}
	 */
	components: {
		Navbar,
		Sidebar,
	},
	/**
	 * The component's computed properties.
	 *
	 * @type {Object}
	 */
	computed: {
		/**
		 * Get the current active partner id from the store.
		 *
		 * @return {?Number}
		 */
		activePartnerId() {
			return this.$store.getters.partner?.id
		},

		/**
		 * Get the wrapper CSS class list.
		 *
		 * @return {Object}
		 */
		classList() {
			return {
				'has-inbox': this.$route.meta.inbox,
				'has-narrow-inbox': this.$route.meta.narrow,
			}
		},
	},

	/**
	 * The component's local methods.
	 *
	 * @type {Object}
	 */
	methods: {
		/**
		 * Fetch the new call from the API when the event is received.
		 *
		 * @param {String} options.call_sid
		 * @return {void}
		 */
		async onNewCall({ data }) {
			if (data.partner_id !== this.activePartnerId) {
				Bugsnag.notify(
					new Error(
						'Received new call event for partner ' +
							data.partner_id +
							' but active partner is ' +
							this.activePartnerId
					)
				)
				return
			}
			try {
				if (this.startDateFilter() && this.endDateFilter()) {
					await this.getIncompleteCallCount()
					if (data && data.types && data.types.length > 0) {
						await data.types.map(type => {
							this.getCallByType(type.id)
						})
					}
				} else {
					if (data.call_count_stats) {
						await this.setIncompleteCallCount({
							urgent: data.call_count_stats.urgent,
							non_urgent: data.call_count_stats.non_urgent,
							total: data.call_count_stats.total,
							setTotal: true,
						})
						await this.incrementPendingCallsCountById({
							total: data.call_count_stats.total,
						})
						if (
							data.call_count_stats.call_types &&
							data.call_count_stats.call_types.length > 0
						) {
							await data.call_count_stats.call_types.map(type => {
								if (type.id && Number.isInteger(type.count)) {
									this.updateCallTypeCount({
										id: type.id,
										total: type.count,
									})
								}
							})
						}
					}
				}

				await this.addRecentCall(data.id)
				await this.addToFront(data)
				await this.addToFilter(data)
			} catch (e) {
				console.error(`Failed to fetch new call [sid: ${data.sid}`)
			}
		},

		/**
		 * Fetch the update call from the API when the event is received.
		 *
		 * @param {String} options.call_sid
		 * @return {void}
		 */
		async onUpdateCall({ data }) {
			if (data.partner_id !== this.activePartnerId) {
				Bugsnag.notify(
					new Error(
						'Received update call event for partner ' +
							data.partner_id +
							' but active partner is ' +
							this.activePartnerId
					)
				)
				return
			}
			try {
				if (this.startDateFilter() && this.endDateFilter()) {
					await this.getIncompleteCallCount()
					await this.getCallTypeCounts()
				} else {
					if (data.call_count_stats) {
						await this.setIncompleteCallCount({
							urgent: data.call_count_stats.urgent,
							non_urgent: data.call_count_stats.non_urgent,
							total: data.call_count_stats.total,
							setTotal: true,
						})
						await this.incrementPendingCallsCountById({
							total: data.call_count_stats.total,
						})
						if (
							data.call_count_stats.call_types &&
							data.call_count_stats.call_types.length > 0
						) {
							await data.call_count_stats.call_types.map(type => {
								if (type.id && Number.isInteger(type.count)) {
									this.updateCallTypeCount({
										id: type.id,
										total: type.count,
									})
								}
							})
						}
					}
				}

				if (data.sid === this.$route.params.sid) {
					const response = await this.$api
						.partners(this.activePartnerId)
						.calls()
						.find(data.sid)
					const call = response.get('data', {})
					await this.addToFront(call)
				} else {
					await this.addToFront(data)
				}
				await this.addRecentCall(data.id)
				await this.updateToFilter(data)
			} catch (e) {
				console.error(`Failed to fetch new call [sid: ${data.sid}`)
			}
		},

		/**
		 * Handle the OMD new release event.
		 *
		 * @param {Object} event
		 * @return {void}
		 */
		onOmdRelease(event) {
			if (event?.force_refresh) {
				this.$alert.warning('omd.release')

				setTimeout(() => location.reload(), 5000)
			}
		},

		/**
		 * Redirect the app to the given partner id.
		 *
		 * @param {Number} id
		 * @return {void}
		 */
		redirectToPartner(id) {
			const name = this.$route.name
			const params = this.$route.params

			this.$router.push({
				name,
				params: {
					...params,
					partner: id,
				},
			})
		},

		/**
		 * Determine if the app should redirect the user to the given partner id.
		 *
		 * @param {Number} id
		 * @return {Boolean}
		 */
		shouldRedirectToPartner(id) {
			const current = parseInt(this.$route.params.partner)

			return id && id !== current
		},

		...mapActions('calls', [
			'addToFront',
			'addToFilter',
			'updateToFilter',
			'addRecentCall',
			'setIncompleteCallCount',
			'getIncompleteCallCount',
			'get',
		]),
		...mapActions({
			getCallByType: 'callTypes/getByType',
			getCallTypeCounts: 'callTypes/get',
			updateCallTypeCount: 'callTypes/updateCallTypeCount',
		}),
		...mapGetters({
			startDateFilter: 'calls/startDateFilter',
			endDateFilter: 'calls/endDateFilter',
		}),
		...mapActions({
			incrementPendingCallsCountById:
				'partners/incrementPendingCallsCountById',
		}),
	},

	/**
	 * The component's name used for debugging.
	 *
	 * @type {String}
	 */
	name: 'Manager',

	/**
	 * The component's property watchers.
	 *
	 * @type {Object}
	 */
	watch: {
		/**
		 * Watch the active partner id for changes.
		 *
		 * @param {Number} id
		 * @return {void}
		 */
		activePartnerId(id) {
			const partner = parseInt(id)

			if (this.shouldRedirectToPartner(partner)) {
				this.redirectToPartner(partner)
			}
		},
	},

	/**
	 * The component's before destroy lifecycle hook.
	 *
	 * @return {void}
	 */
	beforeDestroy() {
		this.$app
			.off('call:new', this.onNewCall)
			.off('call:updated', this.onUpdateCall)
			.off('omd:updated', this.onOmdRelease)
	},

	/**
	 * The component's created lifecycle hook.
	 *
	 * @return {void}
	 */
	async created() {
		this.$app
			.on('call:new', this.onNewCall)
			.on('call:updated', this.onUpdateCall)
			.on('omd:updated', this.onOmdRelease)
	},
}
</script>
